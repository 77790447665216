// src/styles/GlobalStyles.js
import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Inter', sans-serif;
    background-color: #F9FAFB;
    color: #2D3748;
    margin: 0;
    padding: 0;
  }

  /* Scrollbar Styling */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #F1F5F9;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #CBD5E0;
    border-radius: 4px;
    border: 2px solid #F1F5F9;
  }
`;

export const Button = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.2s;

  &.primary {
    background-color: #5A67D8;
    color: #fff;

    &:hover {
      background-color: #434190;
    }
  }

  &.secondary {
    background-color: #4A5568;
    color: #fff;

    &:hover {
      background-color: #2D3748;
    }
  }

  &.danger {
    background-color: #E53E3E;
    color: #fff;

    &:hover {
      background-color: #C53030;
    }
  }

  &.link {
    background: none;
    color: #5A67D8;
    padding: 0;
    font-size: 14px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Input = styled.input`
  padding: 8px 12px;
  border: 1px solid #CBD5E0;
  border-radius: 6px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #5A67D8;
    box-shadow: 0 0 0 2px rgba(90, 103, 216, 0.2);
  }
`;

export const Select = styled.select`
  padding: 8px 12px;
  border: 1px solid #CBD5E0;
  border-radius: 6px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #5A67D8;
    box-shadow: 0 0 0 2px rgba(90, 103, 216, 0.2);
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background-color: #fff;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  padding: 24px;
  position: relative;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #A0AEC0;

  &:hover {
    color: #4A5568;
  }
`;
