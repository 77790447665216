// NavigationBar.js

import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import SettingsIcon from '@mui/icons-material/Settings';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import InventoryIcon from '@mui/icons-material/Inventory'; // Orders Icon
import BusinessIcon from '@mui/icons-material/Business'; // Enterprise Icon
import AssessmentIcon from '@mui/icons-material/Assessment'; // Reports Icon
import ChevronDownIcon from '@mui/icons-material/ExpandMore'; // Down arrow icon
import ChevronRightIcon from '@mui/icons-material/ChevronRight'; // Right arrow icon
import AndyAiIcon from '../assets/AndyAI-Ready.png'; // Adjust the path as necessary
import { useAuth } from '../contexts/AuthContext';
import useIsPlatformAdmin from '../utils/useIsPlatformAdmin';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'; // Right arrow icon
import BuildIcon from '@mui/icons-material/Build';

// Color Constants
const COLORS = {
  background: '#FFFFFF', // White background
  active: '#6200EE', // Bright purple remains unchanged
  inactive: '#4A4A4A', // Darker gray for inactive items
  divider: '#E0E0E0', // Light gray divider
  hoverBackground: 'rgba(98, 0, 238, 0.05)', // Softer hover effect
  activeBackground: 'rgba(98, 0, 238, 0.1)', // Light purple background for active state
};

// Styled Components with Transient Props (prefixed with $)
const Nav = styled.nav`
  background: ${COLORS.background};
  color: ${COLORS.inactive};
  width: ${({ $collapsed }) => ($collapsed ? '80px' : '200px')}; /* Increased width */
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 12px; /* Slightly increased padding */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: width 0.3s ease;
  font-family: 'Roboto', sans-serif; /* Updated font family */
  flex-shrink: 0; /* Prevent navbar from shrinking */
`;

const BrandLink = styled(NavLink)`
  display: block;
  margin: 0 auto 25px auto; /* Reduced bottom margin for tighter spacing */
  width: 100%;
  max-width: ${({ $collapsed }) => ($collapsed ? '40px' : '80px')};
  transition: max-width 0.3s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const BrandIcon = styled.img`
  width: 100%;
  height: auto;
  display: block;
  margin-top: 15px; /* Reduced top margin for tighter spacing */
`;

const NavLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px; /* Reduced vertical spacing for a tighter layout */
  flex-grow: 1;
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  color: ${COLORS.inactive};
  padding: 14px 18px; /* Reduced padding for a tighter layout */
  text-decoration: none;
  border-radius: 8px; /* Slightly reduced border radius for a sleeker look */
  font-size: 14px; /* Maintained font size */
  font-weight: 500; /* Increased font weight for better visibility */
  transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease;
  position: relative; /* Added for "Coming Soon" Badge */

  &.active {
    color: ${COLORS.active}; /* Change text color when active */
    background-color: ${COLORS.activeBackground}; /* Light purple background for active state */
    font-weight: 600; /* Increased font weight for better visibility */
  }

  &:hover {
    background-color: ${COLORS.hoverBackground}; /* Softer hover effect */
    transform: scale(1.02); /* Subtle scaling */
    color: ${COLORS.active}; /* Change text color on hover */
  }

  svg {
    margin-right: ${({ $collapsed }) => ($collapsed ? '0' : '10px')};
    transition: margin-right 0.3s ease, transform 0.3s ease, color 0.3s ease;
    font-size: 18px; /* Maintained icon size */
    color: inherit; /* Inherit color based on active state */
  }
`;

const ComingSoonBadge = styled.span`
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #ff6347; /* Tomato color for visibility */
  color: white;
  font-size: 10px;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 12px;
`;

const ToggleButton = styled.button`
  background: transparent;
  border: none;
  color: ${COLORS.inactive};
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* Ensure the button spans the full width */
  border-radius: 8px; /* Match border radius with nav items */
  margin-top: 10px; /* Reduced top margin for tighter spacing */

  &:hover {
    background-color: ${COLORS.hoverBackground}; /* Match hover effect */
    color: ${COLORS.active};
  }

  svg {
    transition: transform 0.3s ease;
    font-size: 24px; /* Maintained icon size */
  }
`;

const LogoutButton = styled.button`
  background: transparent;
  color: ${COLORS.inactive};
  border: none;
  padding: 14px 18px; /* Reduced padding for a tighter layout */
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  font-size: 14px; /* Maintained font size */
  font-weight: 400; /* Increased font weight for better visibility */
  border-radius: 8px; /* Slightly reduced border radius for a sleeker look */
  transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: ${COLORS.hoverBackground}; /* Softer hover effect */
    color: ${COLORS.active};
    transform: scale(1.02); /* Subtle scaling */
  }

  svg {
    margin-right: 10px; /* Maintained margin */
    transition: margin-right 0.3s ease, color 0.3s ease;
    font-size: 18px; /* Maintained icon size */
    color: inherit; /* Inherit color based on hover state */
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid ${COLORS.divider}; /* Updated divider color */
  margin: 4px 0; /* Reduced margin for tighter spacing */
`;

// Styled Components for Dropdown
const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.inactive};
  padding: 14px 18px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: ${({ $isBuildToolsOpen }) => ($isBuildToolsOpen ? '8px' : '0px')};
  transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease;
  &:hover {
    background-color: ${COLORS.hoverBackground};
    transform: scale(1.02);
    color: ${COLORS.active};
  }

  svg {
    margin-right: ${({ $isBuildToolsOpen }) => ($isBuildToolsOpen ? '8px' : '8px')};
    transition: margin-right 0.3s ease, transform 0.3s ease, color 0.3s ease;
    font-size: 16px;
    color: inherit;
    /* Removed rotation since we're using different icons for open/closed states */
  }
`;

const DropdownMenu = styled.div`
  display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
  flex-direction: column;
  margin-left: ${({ $collapsed }) => ($collapsed ? '0px' : '24px')}; /* Indent submenu items */
  gap: 8px; /* Reduced vertical spacing for a tighter layout */
  transition: all 0.3s ease;
`;

const DropdownNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  color: ${COLORS.inactive};
  padding: 10px 18px;
  text-decoration: none;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease;

  &.active {
    color: ${COLORS.active};
    background-color: ${COLORS.activeBackground};
    font-weight: 600;
  }

  &:hover {
    background-color: ${COLORS.hoverBackground};
    transform: scale(1.02);
    color: ${COLORS.active};
  }

  svg {
    margin-right: ${({ $collapsed }) => ($collapsed ? '0' : '8px')};
    transition: margin-right 0.3s ease, transform 0.3s ease, color 0.3s ease;
    font-size: 16px;
    color: inherit;
  }
`;

const NavigationBar = () => {
  const { logout } = useAuth();
  const isPlatformAdmin = useIsPlatformAdmin(); // Utilize the custom hook

  const [collapsed, setCollapsed] = useState(false);
  const [isBuildToolsOpen, setIsBuildToolsOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout(); // Clear authentication
    navigate('/login');
  };

  const toggleBuildTools = () => {
    setIsBuildToolsOpen(prevState => !prevState);
  };

  return (
    <Nav $collapsed={collapsed}>
      <div>
        <BrandLink to="/" $collapsed={collapsed}>
          <BrandIcon src={AndyAiIcon} alt="AndyAI Logo" />
        </BrandLink>

        <NavLinks>
          <StyledNavLink to="/dashboard" $collapsed={collapsed}>
            <DashboardIcon />
            {!collapsed && 'Dashboard'}
          </StyledNavLink>
          <StyledNavLink to="/reports" $collapsed={collapsed}>
            <AssessmentIcon />
            {!collapsed && (
              <>
                Reports
                <ComingSoonBadge>Soon</ComingSoonBadge>
              </>
            )}
          </StyledNavLink>

          <StyledNavLink to="/orders" $collapsed={collapsed}>
            <InventoryIcon />
            {!collapsed && (
              <>
                Orders
                <ComingSoonBadge>Soon</ComingSoonBadge>
              </>
            )}
          </StyledNavLink>

          <StyledNavLink to="/agents" $collapsed={collapsed}>
            <PeopleIcon />
            {!collapsed && 'Agents'}
          </StyledNavLink>
          <StyledNavLink to="/chat-logs" $collapsed={collapsed}>
            <ManageSearchIcon />
            {!collapsed && 'Chat Logs'}
          </StyledNavLink>

          <Divider /> 
          {/* Dropdown for Build Tools */}
          <DropdownContainer>
          <DropdownHeader
  onClick={toggleBuildTools}
  $isBuildToolsOpen={isBuildToolsOpen}
  $collapsed={collapsed}
  aria-label="Toggle Build Tools Dropdown"
>
  <BuildIcon/> 
  {!collapsed && (
    <>
      <span style={{ marginLeft: '0px' }}>Build Tools</span>
    </>
  )}
  <div style={{ marginLeft: '18px' }}>
    {!collapsed && (
      isBuildToolsOpen ? <ChevronDownIcon /> : <ChevronRightIcon />
    )}                  
  </div>
</DropdownHeader>

            <DropdownMenu $isOpen={isBuildToolsOpen} $collapsed={collapsed}>
              {/* Training */}
              
              <DropdownNavLink to="/training" $collapsed={collapsed}>
                <FitnessCenterIcon />
                {!collapsed && 'Training'}
              </DropdownNavLink>
              
              {/* Settings */}
              <DropdownNavLink to="/settings" $collapsed={collapsed}>
                <SettingsIcon />
                {!collapsed && 'Settings'}
              </DropdownNavLink>

              {/* Enterprises (Admin Only) */}
              {isPlatformAdmin && (
                <DropdownNavLink to="/enterprises" $collapsed={collapsed}>
                  <BusinessIcon />
                  {!collapsed && 'Enterprises'}
                </DropdownNavLink>
              )}

              {/* Users (Admin Only) */}
              {isPlatformAdmin && (
                <DropdownNavLink to="/user-access" $collapsed={collapsed}>
                  <SupervisedUserCircleIcon />
                  {!collapsed && 'Users'}
                </DropdownNavLink>
              )}
            </DropdownMenu>
          </DropdownContainer>

          <Divider /> 
        </NavLinks>
      </div>

      <div>
        <LogoutButton onClick={handleLogout}>
          <ExitToAppIcon />
          {!collapsed && 'Logout'}
        </LogoutButton>
        <ToggleButton onClick={() => setCollapsed(!collapsed)} aria-label="Toggle Navigation">
          {collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </ToggleButton>
      </div>
    </Nav>
  );
};

export default NavigationBar;
