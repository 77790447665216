// Orders.js
import React from 'react';
import styled from 'styled-components';
import DashboardHeader from './DashboardHeader';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

const ComingSoonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: Rgb(244,244,249)  ;
  padding: 20px;
`;

const Section = styled.div`
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  text-align: center;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: 700;
  color: #4c4c4c;
  margin-bottom: 20px;
`;

const Subtitle = styled.p`
  font-size: 18px;
  color: #666;
  margin-bottom: 30px;
`;

const IconWrapper = styled.div`
  margin-bottom: 30px;
  color: #8b5cf6;
`;

const RocketIcon = styled(RocketLaunchIcon)`
  font-size: 80px !important;
  color: #8b5cf6;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const Orders = () => {
  return (
    <>
      <DashboardHeader />
      <ComingSoonContainer>
        <Section>
          <IconWrapper>
            <RocketIcon />
          </IconWrapper>
          <Title>Coming Soon</Title>
          <Subtitle>We are working hard to launch this feature soon. Stay tuned for more updates!</Subtitle>
        </Section>
      </ComingSoonContainer>
    </>
  );
};

export default Orders;