// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import { AuthProvider } from './contexts/AuthContext';
import { GlobalStyle } from './styles/GlobalStyles';

// Import ToastContainer and CSS
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Public Pages
import LandingPage from './components/Public/LandingPage';
import PrivacyPolicy from './components/Public/PrivacyPolicy';
import AboutUs from './components/Public/AboutUs';
import Newsletter from './components/Public/Newsletter';

// Private Pages
import NavigationBar from './components/NavigationBar';
import Dashboard from './components/Dashboard';
import Agents from './components/Agents';
import Training from './components/Training';
import Settings from './components/Settings';
import UserAccess from './components/UserAccess';
import Orders from './components/Orders';
import Reports from './components/Reports';
import Enterprises from './components/Enterprises';

// Auth Pages
import LoginPage from './components/LoginPage';
import SignupPage from './components/SignupPage';
import ForgotPasswordPage from './components/ForgotPasswordPage';
import NotFoundPage from './components/Public/NotFoundPage';
import Unauthorized from './components/Unauthorized';

// Route Protection Component
import PrivateRoute from './components/PrivateRoute';
import ChatLogs from './components/ChatLog';
import ProtectedRoute from './components/ProtectedRoute';
import PublicLayout from './components/Public/PublicLayout';
import PrivateLayout from './components/Private/PrivateLayout';

const AppContainer = styled.div`
  display: flex;
  height: 100vh; 
  overflow: hidden; 
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: rgb(244, 244, 249); /* Set the desired background color for private routes */
`;

function Layout() {

  return (
    <>
        <Routes>
          {/* Public Routes */}
          <Route element={<PublicLayout/>}>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/newsletter" element={<Newsletter />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
          
          
          {/* Protected Routes */}
          <Route element={<PrivateLayout/>}>
          <Route
            path="/dashboard"
            element={
              <PrivateRoute requiredRoles={['admin', 'team', 'support']}>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/agents"
            element={
              <PrivateRoute requiredRoles={['admin', 'team']}>
                <Agents />
              </PrivateRoute>
            }
          />
          <Route
            path="/training"
            element={
              <PrivateRoute requiredRoles={['admin', 'support']}>
                <Training />
              </PrivateRoute>
            }
          />
          <Route
            path="/chat-logs"
            element={
              <PrivateRoute requiredRoles={['admin', 'support']}>
                <ChatLogs />
              </PrivateRoute>
            }
          />
          <Route
            path="/reports"
            element={
              <PrivateRoute requiredRoles={['admin']}>
                <Reports />
              </PrivateRoute>
            }
          />
          <Route
            path="/orders"
            element={
              <PrivateRoute requiredRoles={['admin']}>
                <Orders />
              </PrivateRoute>
            }
          />
          <Route
            path="/user-access"
            element={
              <ProtectedRoute>
                <UserAccess />
              </ProtectedRoute>
            }
          />
          <Route
            path="/enterprises"
            element={
              <ProtectedRoute>
                <Enterprises />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute requiredRoles={['admin', 'team']}>
                <Settings />
              </PrivateRoute>
            }
          />
          <Route path="/unauthorized" element={<Unauthorized />} />
          </Route>
          
        </Routes>
      
      {/* Include ToastContainer here */}
      <ToastContainer 
        position="bottom-right"
        autoClose={5000} // Automatically close after 5 seconds
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored" // You can choose different themes: "light", "dark", "colored"
      />
    </>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <GlobalStyle />
        <Layout />
      </Router>
    </AuthProvider>
  );
}

export default App;
