// DashboardHeader.js
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Brightness7RoundedIcon from '@mui/icons-material/Brightness7Rounded'; // Modern sun icon
import DarkModeIcon from '@mui/icons-material/DarkMode'; // Dark theme icon
import MemoryIcon from '@mui/icons-material/Memory'; // AI theme icon
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

// Styled Icons using CSS variables
const SunIcon = styled(Brightness7RoundedIcon)`
  color: var(--color-light-icon);
  filter: drop-shadow(0 0 10px var(--color-light-icon));
`;

const MoonIcon = styled(DarkModeIcon)`
  color: var(--color-dark-icon);
`;

const AiIcon = styled(MemoryIcon)`
  color: var(--color-ai-icon);
`;

// Wrapper for the header background color, updated with dynamic props for theme
const HeaderWrapper = styled.div`
  background: ${({ theme }) =>
    theme === 'light'
      ? 'var(--color-light-overall-background)'
      : theme === 'dark'
      ? 'var(--color-dark-background)'
      : 'var(--color-ai-background)'};
  padding: 20px 50px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) =>
    theme === 'light'
      ? 'var(--color-light-background)'
      : theme === 'dark'
      ? 'var(--color-dark-background-search)'
      : 'var(--color-ai-gradient)'};
  color: ${({ theme }) =>
    theme === 'light'
      ? 'var(--color-light-text)'
      : 'var(--color-dark-text)'};
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: ${({ theme }) =>
    theme === 'ai'
      ? '0 4px 12px rgba(106, 17, 203, 0.5)'
      : '0 2px 6px rgba(0, 0, 0, 0.08)'};
  height: 55px;
`;

const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  gap: 24px; /* Increased gap for better spacing */
`;

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-left: 20px;
  flex-grow: 1;

  input {
    border: none;
    outline: none;
    font-size: 16px;
    color: ${({ theme }) =>
      theme === 'light'
        ? 'var(--color-light-text)'
        : 'var(--color-dark-text)'};
    margin-left: 10px;
    background: transparent;
    width: 100%;
  }
`;

// Language Selector Styled Components
const LanguageSelect = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  position: relative;
`;

const LanguageDropdown = styled.div`
  position: absolute;
  background: ${({ theme }) =>
    theme === 'light'
      ? 'var(--color-light-background)'
      : 'var(--color-dark-background)'};
  border: 1px solid
    ${({ theme }) =>
      theme === 'light'
        ? 'var(--color-light-border)'
        : 'var(--color-dark-border)'};
  border-radius: 4px;
  top: 50px;
  z-index: 11;
  width: 200px;
  padding: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
`;

const LanguageOption = styled.div`
  color: ${({ theme }) =>
    theme === 'light'
      ? 'var(--color-light-text)'
      : 'var(--color-dark-text)'};
  font-size: 14px;
`;

// Theme Selector Styled Components
const ThemeSelect = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  position: relative;
`;

const ThemeDropdown = styled.div`
  position: absolute;
  background: ${({ theme }) =>
    theme === 'light'
      ? 'var(--color-light-background)'
      : 'var(--color-dark-background)'};
  border: 1px solid
    ${({ theme }) =>
      theme === 'light'
        ? 'var(--color-light-border)'
        : 'var(--color-dark-border)'};
  border-radius: 4px;
  top: 50px;
  z-index: 11;
  width: 150px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
`;

const ThemeOption = styled.div`
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) =>
    theme === 'light'
      ? 'var(--color-light-text)'
      : 'var(--color-dark-text)'};

  &:hover {
    background: ${({ theme }) =>
      theme === 'light'
        ? 'var(--color-light-hover-bg)'
        : 'var(--color-dark-hover-bg)'};
  }

  svg {
    color: ${({ theme }) =>
      theme === 'light'
        ? 'var(--color-light-icon)'
        : 'var(--color-dark-icon)'};
  }
`;

// Notification Icon Styled Component
const NotificationIcon = styled(NotificationsNoneIcon)`
  font-size: 28px;
  cursor: pointer;
  transition: color 0.3s;

  color: ${({ theme }) =>
    theme === 'light'
      ? 'var(--color-light-text)'
      : 'var(--color-dark-text)'};

  &:hover {
    color: #5f0a87;
  }
`;

// Profile Section Styled Components
const ProfileSelect = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  position: relative;
`;

const ProfileIcon = styled(AccountCircleIcon)`
  font-size: 48px;
  color: ${({ theme }) =>
    theme === 'light'
      ? 'var(--color-light-text)'
      : 'var(--color-dark-text)'};
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  color: ${({ theme }) =>
    theme === 'light'
      ? 'var(--color-light-text)'
      : 'var(--color-dark-text)'};

  .name {
    font-weight: 600;
  }
  .role {
    font-weight: 400;
    color: ${({ theme }) =>
      theme === 'light' ? '#666' : '#aaa'};
  }
`;

const ProfileDropdown = styled.div`
  position: absolute;
  background: ${({ theme }) =>
    theme === 'light'
      ? 'var(--color-light-background)'
      : 'var(--color-dark-background)'};
  border: 1px solid
    ${({ theme }) =>
      theme === 'light'
        ? 'var(--color-light-border)'
        : 'var(--color-dark-border)'};
  border-radius: 4px;
  top: 60px;
  right: 0;
  z-index: 11;
  width: 150px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
`;

const ProfileOption = styled.div`
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) =>
    theme === 'light'
      ? 'var(--color-light-text)'
      : 'var(--color-dark-text)'};

  &:hover {
    background: ${({ theme }) =>
      theme === 'light'
        ? 'var(--color-light-hover-bg)'
        : 'var(--color-dark-hover-bg)'};
  }

  svg {
    color: ${({ theme }) =>
      theme === 'light'
        ? 'var(--color-light-icon-secondary)'
        : 'var(--color-dark-icon)'};
  }
`;

const DashboardHeader = () => {
  const [theme, setTheme] = useState('light');
  const [isThemeDropdownOpen, setThemeDropdownOpen] = useState(false);
  const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [isLanguageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const { logout, enterpriseId, currentUser, isPlatformAdmin } = useAuth();
  console.log("🚀 ~ DashboardHeader ~ currentUser:", currentUser)
  const [enterpriseName, setEnterpriseName] = useState('');
  const navigate = useNavigate();

  const toggleThemeDropdown = () => {
    setThemeDropdownOpen(!isThemeDropdownOpen);
    setProfileDropdownOpen(false);
    setLanguageDropdownOpen(false);
  };
  
  const toggleProfileDropdown = () => {
    setProfileDropdownOpen(!isProfileDropdownOpen);
    setThemeDropdownOpen(false);
    setLanguageDropdownOpen(false);
  };
  
  const toggleLanguageDropdown = () => {
    setLanguageDropdownOpen(!isLanguageDropdownOpen);
    setThemeDropdownOpen(false);
    setProfileDropdownOpen(false);
  };
  

  const changeTheme = (newTheme) => {
    setTheme(newTheme);
    setThemeDropdownOpen(false);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const goToSettings = () => {
    navigate('/settings');
  };

  useEffect(() => {
    const fetchEnterpriseName = async () => {
      if (enterpriseId) {
        try {
          const enterpriseRef = doc(db, 'enterprises', enterpriseId);
          const enterpriseSnap = await getDoc(enterpriseRef);
          if (enterpriseSnap.exists()) {
            const enterpriseData = enterpriseSnap.data();
            console.log("🚀 ~ fetchEnterpriseName ~ enterpriseData:", enterpriseData)
            setEnterpriseName(enterpriseData.name || 'Enterprise');
          } else {
            setEnterpriseName('Enterprise'); // Fallback if enterprise document doesn't exist
          }
        } catch (error) {
          console.error('Error fetching enterprise name:', error);
          setEnterpriseName('Enterprise'); // Fallback on error
        }
      } else {
        setEnterpriseName('Enterprise'); // Fallback if no enterpriseId
      }
    };
  
    fetchEnterpriseName();
  }, [enterpriseId]);
  

  const currentThemeIcon =
    theme === 'light' ? (
      <SunIcon />
    ) : theme === 'dark' ? (
      <MoonIcon />
    ) : (
      <AiIcon />
    );

  return (
    <HeaderWrapper theme={theme}>
      <HeaderContainer theme={theme}>
        {/* Search Bar */}
        <SearchBar theme={theme}>
          <SearchIcon />
          <input type="text" placeholder="Search..." />
        </SearchBar>

        {/* Language Dropdown, Theme Selector, Notifications, Profile */}
        <HeaderSection>
          {/* Language Dropdown */}
          <LanguageSelect onClick={toggleLanguageDropdown} theme={theme}>
            <img
              src="https://cdn-icons-png.flaticon.com/512/197/197484.png"
              alt="USA Flag"
              style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
              }}
            />
            Eng (US)
            <ArrowDropDownIcon />
            {isLanguageDropdownOpen && (
              <LanguageDropdown theme={theme}>
                <LanguageOption theme={theme}>
                  Only English Available Now
                </LanguageOption>
              </LanguageDropdown>
            )}
          </LanguageSelect>

          {/* Theme Dropdown */}
          <ThemeSelect onClick={toggleThemeDropdown}>
            {currentThemeIcon}
            <ArrowDropDownIcon />
            {isThemeDropdownOpen && (
              <ThemeDropdown theme={theme}>
                <ThemeOption
                  theme={theme}
                  onClick={() => changeTheme('light')}
                >
                  <Brightness7RoundedIcon />
                  Light
                </ThemeOption>
                <ThemeOption
                  theme={theme}
                  onClick={() => changeTheme('dark')}
                >
                  <DarkModeIcon />
                  Dark
                </ThemeOption>
                <ThemeOption
                  theme={theme}
                  onClick={() => changeTheme('ai')}
                >
                  <MemoryIcon />
                  AI Theme
                </ThemeOption>
              </ThemeDropdown>
            )}
          </ThemeSelect>

          {/* Notification Icon */}
          <NotificationIcon theme={theme} />

          {/* Profile Section */}
          <ProfileSelect onClick={toggleProfileDropdown}>
            <ProfileIcon theme={theme} />
            <ProfileInfo theme={theme}>
              <span className="name">{isPlatformAdmin? "AndyAI" : enterpriseName}</span>
              <span className="role">{isPlatformAdmin? "Platform Admin" : currentUser.displayName || "Support"}</span>
            </ProfileInfo>

            <ArrowDropDownIcon />
            {isProfileDropdownOpen && (
              <ProfileDropdown theme={theme}>
                <ProfileOption theme={theme} onClick={goToSettings}>
                  <SettingsIcon />
                  Settings
                </ProfileOption>
                <ProfileOption theme={theme} onClick={handleLogout}>
                  <ExitToAppIcon />
                  Logout
                </ProfileOption>
              </ProfileDropdown>
            )}
          </ProfileSelect>
        </HeaderSection>
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default DashboardHeader;
